@import '~@fortawesome/fontawesome-free/css/all.css';

@media (max-width: 1500px) {
    .p-datatable .p-datatable-thead>tr>th {
        font-size: 13px !important;
    }
}

@media (max-width: 1500px) {

    .p-datatable .p-datatable-thead>tr>th {
        min-width: 150px;
    }

    .p-datatable {
        overflow-x: auto;
    }
}