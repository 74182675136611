* {
  box-sizing: border-box;
  text-decoration: none;
  outline: none;
}

:root {
  --primary: #37495F;
  --secondary: #6AA9A1;
  --dark-gray: #CCCCCC;
  --dark-gray-light: #F5F5F5;
  --dark-text: "#3B3C3E";
  --icon: #00668C;
  --gray-text: #9F9F9F;
  --light-blue: #e4ecf1;
  --transition: 0.5s all ease-in-out;
}

html {
  scroll-behavior: smooth;
}

.container {
  margin: auto;
  padding: 10px 0;
}

/* 
  #####################              #####################
  #####################              #####################
  ##################### Gloabl Style #####################
  #####################              #####################
  #####################              #####################
*/

body {
  direction: rtl;
  font-family: 'Almarai', sans-serif;
  font-family: 'Cairo', sans-serif;
  font-family: 'Chivo', sans-serif;
  font-family: 'Hind', sans-serif;
  font-family: 'Italianno', cursive;
  font-family: 'Lato', sans-serif;
  font-family: 'Oswald', sans-serif;
  font-family: 'Poppins', sans-serif;
  font-family: 'Readex Pro', sans-serif;
}

.swal2-container {
  z-index: 3000 !important;
}

svg,
i {
  cursor: pointer;
}

input:focus,
button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #C7D2FE;
  border-color: #6366F1;
}

.default-shadow {
  outline: 0 none !important;
  outline-offset: 0 !important;
  box-shadow: 0 0 0 0.3rem #C7D2FE !important;
  border-color: #6366F1 !important;
}

.transition {
  transition: 0.5s all ease-in-out !important;
}

.position-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.hide-input {
  width: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;
  appearance: none;
  display: none;
}

.loader {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: inline-block;
  border-top: 3px solid var(--primary);
  border-right: 3px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.num-container {
  width: 54px;
  height: 60px;
  background-color: #CBE1EE;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 3px;
  border-radius: 10px 10px 20px 20px;
  z-index: 111;
  border: 1px solid #71C4F0;
}

.num-container h1 {
  font-size: 20px;
}

.num-container p {
  font-size: 7px;
}

.active-section {
  background-color: #5A54A3;
  height: 70%;
  width: 5px;
  border-radius: 5px 0px 0px 5px;
  padding: 10px 0;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.circle::after {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #5A54A2;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.p-button.p-button-icon-only {
  background-color: #DEDEDE !important;
  border-color: #DEDEDE !important;
  color: #37495F !important;
}

/* 
  #####################              #####################
  #####################              #####################
  #####################  PrimeReact  #####################
  #####################              #####################
  #####################              #####################
*/
.p-datepicker:not(.p-datepicker-inline) {
  direction: ltr !important;
}

.p-datatable .p-sortable-column:focus {
  box-shadow: none !important;
}

.grid-nogutter {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.p-dataview-emptymessage {
  grid-column: span 12 / span 12 !important;
}

.p-datatable .p-paginator-bottom {
  direction: ltr;
}

.p-dropdown .p-dropdown-label.p-placeholder {
  color: #D9D9D9 !important;
}

.p-input-icon-right>.p-inputtext {
  width: 100% !important;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator button {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50% !important;
  background-color: #34d1ba68 !important;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  width: 30px !important;
  background-color: #34D1BA !important;
  border-radius: 10px !important;
}

.p-input-icon-left>i,
.p-input-icon-right>i,
.p-input-icon-left>svg,
.p-input-icon-right>svg,
.p-input-icon-left>.p-input-prefix,
.p-input-icon-right>.p-input-suffix {
  margin-top: 0 !important;
  transform: translateY(-50%);
  right: initial !important;
  left: 15px !important;
  cursor: pointer;
}

.p-input-icon-right>.p-inputtext {
  padding-right: 15px !important;
}

.p-calendar-w-btn-right .p-datepicker-trigger {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.p-calendar-w-btn-right .p-inputtext {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.p-dataview .p-paginator-bottom {
  direction: ltr;
}

.p-inline-message.p-inline-message-error {
  margin: 10px 0;
  width: 100%;
}


.p-datatable .p-datatable-thead>tr>th {
  background-color: transparent !important;
  border: none !important;
  margin-bottom: 20px !important;
  padding: 20px 1rem !important;
}

.p-datatable>.p-datatable-wrapper {
  overflow: initial !important;
}

.p-datatable-table thead {
  border-radius: 5px;
  background: #FFF;
}

.p-datatable .p-datatable-tbody>tr {
  border: 1px solid #F3F3F3;
  border-radius: 10px;
}

.p-datatable .p-datatable-header {
  background-color: transparent !important;
  border: none !important;
}

.origins-info .p-dataview .p-dataview-content,
.origins-info .p-paginator {
  background-color: transparent !important;
}

.login .p-inputtext {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.p-datatable .p-datatable-tbody>tr:hover {
  transition: 0.8s ease all;
  scale: 1.08 !important;
  box-shadow: 0px 0px 6px 0px #34D1BA;
}

/* 
  #####################              #####################
  #####################              #####################
  #####################  Animations  #####################
  #####################              #####################
  #####################              #####################
*/

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}




/* 
  #####################              #####################
  #####################              #####################
  #####################  End Styles  #####################
  #####################              #####################
  #####################              #####################
*/