@media (max-width: 1280px) {
    .main-menu.hide {
        right: -300px;
        opacity: 0;
    }

    .main-menu.show {
        right: 0;
        opacity: 1;
    }
}

.main-menu {
    width: 300px;
    transition: 0.4s all ease-in-out;
    backface-visibility: hidden;
    position: fixed;
    z-index: 1031;
    display: table-cell;
    height: 100%;
}

.main-menu-content {
    transition: 1s;
}

.main-menu-content .p-panelmenu .p-panelmenu-panel,
.p-panelmenu .p-panelmenu-header.p-highlight>a,
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
    margin-bottom: 0.75rem;
    padding: 0.75rem !important;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
    padding: 0.35rem !important;
}


.main-menu-content .p-panelmenu .p-panelmenu-header>a {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
}

.main-menu-content .p-panelmenu .p-panelmenu-content {
    border: none !important;
    background-color: transparent !important;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
    background-color: transparent !important;
    box-shadow: none !important;
}

.main-menu-content .p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled)>a,
.p-panelmenu .p-panelmenu-header.p-highlight>a {
    padding: 0 !important;
    justify-content: space-between;
    flex-direction: row-reverse;
}


.main-menu-content li>div {
    width: 100%;
    border-radius: 6px;
}

.p-panelmenu .p-submenu-list>li:last-child a {
    margin-bottom: 0 !important;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-text {
    width: 100%;
}

.main-menu-content a.active {
    background-color: var(--light-blue) !important;
    color: black !important;
    border-radius: 6px !important;
}